<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>資料匯出</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="showLoading">
      <b-card>
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
            資料匯出
          </h4>
        </div>
        <div class="row mt-5">
          <div class="col-12">
            <b-overlay rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="downloadFiles"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(action)="data">
                <div class="row">
                    <b-button
                    variant="inverse-info"
                    v-if="checkPermission([consts.MY_EXPORT]) && data.item.status === 'completed' && checkDate(data.item.expired_at)"
                    :to="{
                        name: 'MyConsoleDownloadFileGetFile',
                        params: {
                            download_file_id: data.item.id,
                        },
                        }"
                    >下載檔案
                    </b-button>
                    <div class="ml-2">
                        <b-button
                            id="copy-tooltip-target"
                            v-if="data.item.show_password"
                            variant="outline-info"
                            v-clipboard:copy="data.item.password"
                            v-b-tooltip.click="'已複製'"
                        >
                            {{ data.item.password }}
                        </b-button>
                        <b-button
                            variant="inverse-info"
                            v-if="
                            checkPermission([consts.MY_EXPORT]) &&
                            data.item.status === 'completed' &&
                            !data.item.show_password &&
                            checkDate(data.item.expired_at)
                            "
                            v-clipboard:copy="data.item.password"
                            v-b-tooltip.click="'已複製'"
                            @click="data.item.show_password = true"
                        >顯示密碼
                        </b-button>
                    </div>
                </div>
              </template>
              </b-table>
            </b-overlay>
          </div>

          <div class="col-12 d-flex justify-content-center" style="margin-top: 80px">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :no-local-sorting="true"
              align="center"
              @change="(page) => fetchDownloadFiles(page)"
            ></b-pagination>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// import _ from 'lodash'
import { format, isBefore } from "date-fns";
import downloadFileApi from "@/apis/download-file";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'
import VueClipboard from "vue-clipboard2";
import Vue from "vue";

Vue.use(VueClipboard);

export default {
  data() {
    return {
      perPage: 15,
      totalRows: 1,
      currentPage: 1,
      consts,
      downloadFiles: [],
      showLoading: false,
    };
  },
  computed: {
    fields() {
      let table = [
          {
            key: "name",
            label: "File Name",
          },
          {
            key: "processing_at",
            label: "Preparation Time",
            formatter: (value) => {
            return value
                ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
                : null;
            },
          },
          {
            key: "completed_at",
            label: "File Ready",
            formatter: (value) => {
            return value
                ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
                : null;
            },
          },
          {
            key: "expired_at",
            label: "Expire Time",
            formatter: (value) => {
            return value
                ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
                : null;
            },
          },
          {
            key: "status",
            label: "Status",
            formatter: (value) => {
              return this.getStatus(value)
            }
          },
          {
            key: "staff_name",
            label: "Creator",
          },
          {
            key: "created_at",
            label: "Creating Time",
            formatter: (value) => {
            return value
                ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
                : null;
            },
          },
          {
            key: "action",
            label: "",
          }
        ]
      return table
    },
  },
  mounted() {
    this.fetchDownloadFiles()
  },
  methods: {
    checkDate(date) {
      if (isBefore(new Date(), new Date(date))) return true
      return false
    },
    getStatus(value) {
      if (value == 'failed') return '失敗'
      if (value == 'completed') return '完成'
      if (value == 'init') return '尚未處理'
      if (value == 'processing') return '處理中'
    },
    async fetchDownloadFiles(page) {
      this.showLoading = true;
      const params = {
        page: page || this.currentPage,
        per_page: this.perPage || 15,
      };
      try {
        const { data } = await downloadFileApi.list(
          params
        );
        this.totalRows = data.meta.total;
        this.downloadFiles = data.data.map((df) => {
          df.show_password = false
          return df
        });
      } catch (e) {
        console.log(e);
      }
      this.showLoading = false;
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
  },
};
</script>

<style>
.table-responsive {
  min-height: 300px;
}
</style>
